export function createToast(header, body, id = undefined, delay = 5000) {
    // Check if a toast container exists
    let toastContainer = $('#toast-container');
    if (toastContainer.length === 0) {
        // Create a toast container if it doesn't exist
        toastContainer = $('<div id="toast-container" class="position-fixed top-0 end-0 p-3" style="z-index: 9999;"></div>');
        $('body').append(toastContainer);
    }

    // Generate a unique ID if the provided ID is empty
    if (!id) {
        id = 'toast-' + Math.floor(Math.random() * 1000000);
    }

    const _body = body ? `<div class="toast-body p-2">${body}</div>` : ''

    // Create the toast element with the given parameters
    let toast = $(`
    <div id="${id}" class="toast text-break" 
     role="alert" aria-live="assertive"
     aria-atomic="true" 
     data-bs-delay="${delay}">
      <div class="toast-header">
        <strong class="me-auto">${header}</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      ${_body}
    </div>
  `);

    toastContainer.append(toast);

    document.getElementById(id).addEventListener('hidden.bs.toast', function () {
        $(this).remove();
    })

    // Initialize and show the toast after adding the event listener
    let toastInstance = new bootstrap.Toast(toast[0]);
    toastInstance.show();
}