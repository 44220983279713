import {copyToClipboard, makeid, pasteFromClipboard} from "@/assets/functions";

export default {
    props: [
        'value'
    ],
    emits: ['update:value'],
    name: "SetUnlockKeyDialog",
    data() {
        return {
            inputs: [
                this.generateRandom(),
                this.generateRandom(),
                this.generateRandom(),
                this.generateRandom()
            ]
        }
    },
    mounted() {
        if (this.value) this.value.map((el, index) => this.inputs[index] = el);
    },
    methods: {
        emitChanges: function () {
            this.$emit('update:value', Array.from(this.inputs).join(''))

        },
        generateRandom: function () {
            return makeid(16);
        },
        copyToClipBoard: copyToClipboard,
        pastFromClipBoard: pasteFromClipboard
    }
}