<template>
  <ChatBox></ChatBox>
</template>
<script>

import ChatBox from "@/components/ChatBoxComponent/ChatBox.vue";

export default {
  name: 'App',
  components: {
    ChatBox,
  }
}
</script>