import {io} from 'socket.io-client'
import CryptoJS from "crypto-js";
import {
    containsArabic,
    copyToClipboard,
    generateColorFromString,
    getUrlQueryParams,
    splitStringIntoParts
} from "@/assets/functions";
import {nextTick} from "vue";
import moment from "moment";
import SetUnlockKeyDialog from "@/components/SetUnlockKeyDialog/SetUnlockKeyDialog.vue";
import MessageToolBar from "@/components/MessageToolBarComponent/MessageToolBar.vue";

export default {
    /**
     * todo: unencrypted message error
     * todo: toggle mobile keyboard after sending message
     * todo: make each message id to make it possible to replay to message
     * todo: show encrypted message somewhere
     * todo: make create group and join it manually
     * todo: improve dialog of password style
     * todo: link with url shorter api
     * todo: add way to send encrypted files and images
     * todo: remove message ability
     * todo: add buttons section on message
     */
    components: {
        SetUnlockKeyDialog,
        MessageToolBar
    },
    name: 'ChatBox',
    computed: {
        moment() {
            return moment
        }
    },
    data() {
        return {
            unlock_key: 'wjecwqqiotlcfihxctomryatqztkpazomvgajefmahklscpnedsxlrmqijvpsjos',
            unlock_key_valid: false,
            message: '',
            incoming_messages: [],
            outgoing_messages: [],
            chat_url: '',
            socket: null,
            room_members: 0,
            connected: false,

            socket_init_data: {
                user_id: '', room_id: '',
            },

            replay_to_message: null
        }
    }, async mounted() {

        this.socket = io({
            query: getUrlQueryParams()
        });

        this.socket.on('room_id', async (room_id) => {
            this.socket_init_data.room_id = room_id;
        });

        this.socket.on('user_id', async (user_id) => {
            this.socket_init_data.user_id = user_id;
        });

        this.socket.on('remove_message', async (msg_id) => {
            this.outgoing_messages = this.outgoing_messages.filter((msg) => msg.msg_id !== msg_id);
            this.incoming_messages = this.incoming_messages.filter((msg) => msg.msg_id !== msg_id);
        });

        this.socket.on('decryption_issue', (data) => {
            if (this.outgoing_messages.find(el => el.msg_id === data.msg_id))
                this.outgoing_messages.map(el => {
                    if (el.msg_id === data.msg_id) {
                        el.msg_err = true;
                    }
                })
        });

        this.socket.on('take_breath', async (message) => {
            const bytes = CryptoJS.AES.decrypt(message, this.unlock_key);
            this.message = bytes.toString(CryptoJS.enc.Utf8);

            alert(`
            خذ نفس لو سمحت.... انتظر قليلاً ثم ارسل
            Take breath... wait then send
            `);
        });

        this.socket.on('routed', async (data) => {
            extracted.call(this, data);

            if (data.name === this.socket.id) {
                this.outgoing_messages.push(data);
            } else {
                this.incoming_messages.push(data);
            }

            await nextTick();
            if (this.$refs.chat_body) this.$refs.chat_body.scrollTop = this.$refs.chat_body.scrollHeight;
        });

        function extracted(data) {
            const bytes = CryptoJS.AES.decrypt(data.message, this.unlock_key);
            data.message = bytes.toString(CryptoJS.enc.Utf8);

            if (containsArabic(data.message)) data.rtl = true;
            if (data.message === '') data.error_decrepting = true;
            if (data.error_decrepting) this.notifyDecryptionIssue(data);
        }

        this.socket.on('replay_to_message', async (data) => {
            extracted.call(this, data);

            if (data.from === this.socket.id) {
                this.outgoing_messages.push(data);
            } else {
                const toMessage = this.getMessages().find((msg) => msg.msg_id === data.msg_id);
                this.incoming_messages.push({...toMessage, ...data});
            }

            await nextTick();
            if (this.$refs.chat_body) this.$refs.chat_body.scrollTop = this.$refs.chat_body.scrollHeight;
        });

        this.socket.on('room_members', (size) => this.room_members = size)

        this.socket.on('connect', () => {
            this.chat_url = this.socket.id;
            this.connected = true;
        });

        this.socket.on('disconnect', () => this.connected = false);
    }, methods: {
        containsArabic: containsArabic,
        generateColorFromString: generateColorFromString,

        copyToClipboard: function () {
            if (this.socket_init_data.room_id !== '') {
                copyToClipboard([window.location.origin, '?room=', (this.socket_init_data.room_id)].join(''))
            } else {
                alert('لم يتم إنشاء المجموعة بعد');
            }
        }, check_unlock_key_validity: function () {
            const regex = /^[a-zA-Z0-9-]{64,}$/;
            return regex.test(this.unlock_key);
        }, emit_message: async function () {
            if (!this.message) return;

            const ciphertext = CryptoJS.AES.encrypt(this.message, this.unlock_key).toString();
            if (!this.replay_to_message) this.socket.emit('message', ciphertext);

            else this.socket.emit('replay_to_message', {
                msg_id: this.replay_to_message.msg_id,
                message: ciphertext,
                from: this.socket.id
            });

            this.message = '';
            this.replay_to_message = null;
            this.$refs.message_input_field.focus();
        }, getMessages: function () {
            return [...this.incoming_messages.map((el) => {
                return {...el, type: 'in'}
            }), this.outgoing_messages.map((el) => {
                return {...el, type: 'out'}
            })].flat().sort((a, b) => a.time - b.time);
        },
        notifyDecryptionIssue: function (data) {
            if (!this.socket) throw Error('Socket not initialized.');
            this.socket.emit('decryption_issue', data)
        },
        getSplitUnlockKey: function () {
            if (!this.unlock_key) return '';
            return splitStringIntoParts(this.unlock_key, 4);
        },
        toggleDialog: function (dialogId) {
            const modelElement = $(dialogId);
            const model = window.bootstrap.Modal.getOrCreateInstance(modelElement)

            modelElement.get(0).addEventListener('hidden.bs.modal', (ev) => {
                //
            });

            model.show();
        },
        toggleReplayToMessage: function (message) {
            this.replay_to_message = this.getMessages().find(msg => msg.msg_id === message.msg_id);
            this.$refs.message_input_field.focus();
        },
        getReplayedToMessage: function (msg_id) {
            return this.getMessages().find(msg => msg.msg_id === msg_id);
        }, removeMessage: function (message) {
            this.socket.emit('remove_message', message.msg_id);
        },
        areMessagesEquals: function () {
            return this.getMessages().length === this.outgoing_messages.length + this.incoming_messages.length;
        }
    },
}