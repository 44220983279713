<template>
  <div>
    <div class="btn-group message-toolbar-container bg-light">
      <button class="btn btn-sm btn-outline-danger" v-if="message.type === 'out'" @click="removeMessage()">حذف</button>
      <button class="btn btn-sm btn-outline-primary" @click="copyToClipboard()">نسخ</button>
      <button class="btn btn-sm btn-outline-primary" v-if="message.type === 'in'" @click="replayToMessage()">رد</button>
      <button class="btn btn-sm btn-outline-primary" @click="toggleEncryptedMessage()">التشفير</button>
    </div>
  </div>
</template>

<script>
import {copyToClipboard} from "@/assets/functions";
import {createToast} from "@/Services/BootstrapToast.service";

export default {
  props: ['message'],
  emits: [
      'update:message',
    'replayToMessage',
    'removeMessage'
  ],
  name: "MessageToolBar",

  created() {
  },
  methods: {
    copyToClipboard: function () {
      copyToClipboard(this.message.message, () => {
        createToast('تم النسخ', '');
      });
    },
    toggleEncryptedMessage: function () {
      createToast('قبل فك التشفير', this.message.encrypted_message);
    },
    replayToMessage: function () {
      this.$emit('replayToMessage', this.message);
    },
    removeMessage: function () {
      this.$emit('removeMessage', this.message);
    }
  }
}
</script>

<style scoped>
.message-toolbar-container {

}

</style>