import {createApp} from 'vue'

import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";

import "./style.css";
import 'socket.io-client'

import App from './App.vue'

import jQuery from "jquery";

window.$ = jQuery;

window.bootstrap = require('bootstrap');

createApp(App).mount('#app')