<template>
  <div>

    <SetUnlockKeyDialog :value="getSplitUnlockKey()"
                        @update:value="(newValue) => unlock_key = newValue">
    </SetUnlockKeyDialog>

    <section class="msger">
      <div class="msger-inputarea input-group">
        <span class="input-group-text" id="basic-addon1">{{ unlock_key.length }}</span>

        <input type="text" style="text-align: center" :class="{
        'valid' : check_unlock_key_validity()
      }" class="msger-input form-control"
               disabled
               placeholder="ادخل المفتاح هنا" :value="getSplitUnlockKey().join('-')">

        <span class="input-group-text" id="basic-addon2">{{ room_members }}</span>
        <button class="btn btn-outline-secondary p-0" type="button"
                @click="toggleDialog('#set_unlock_key')"
        >
          <img src="../../assets/icns/settings.svg" alt="setting-button" class="mid-icon">
        </button>
      </div>

      <header class="msger-header p-0" v-if="connected">
        <div class="msger-header-title w-100 text-center d-flex justify-content-center align-items-center flex-wrap p-2"
             dir="rtl">
          <button class="btn-link btn btn-sm text-decoration-none"
                  :class="{  'text-danger' : !connected }" @click="
          copyToClipboard(chat_url)"> {{ socket_init_data.room_id }}br
            <small>({{ socket_init_data.user_id }})</small>
          </button>
          <small>قم بالضغظ على الرابط لنسخة وارساله لإحد</small>
        </div>
        <div class="msger-header-options">
          <span><i class="fas fa-cog"></i></span>
        </div>
      </header>

      <header v-else class="msger-header p-0 d-flex flex-wrap justify-content-center align-items-center p-3">
        <div class="spinner-border spinner-border-sm" role="status">
          <span class="visually-hidden"></span>
        </div>
        <span class="mx-2" dir="rtl">جاري الاتصال....</span>
      </header>

      <main class="msger-chat" ref="chat_body">
        <div class="msger-center-hint text-black-50 h-100 w-100 d-flex justify-content-center align-items-center"
             v-if="getMessages().length < 1">

          <div class="text-center text-break">
            <p class="p-0 m-0 text-danger">عند تحديث الصفحة يتم حذف جميع الرسائل</p>
            <p class="p-0 m-0 text-danger">وبعدها يتم إنشاء محادثة جديدة, يجب مشاركة رابط المجموعة مجدداً</p>
            <b class="p-0 m-0 text-success">عدد الاشخاص المتصلين ( {{ room_members }} ) في بعض الاحيان يكون هذا الرقم
              غير دقيق</b>
            <p class="p-0 m-0 text-success"></p>
          </div>

        </div>

        <template v-for="message in getMessages()" :key="message">
          <div class="msg left-msg" v-if="message.type === 'in'">
            <div
                class="msg-img shadow" :style="{backgroundColor : generateColorFromString(message.name)}"
            ></div>

            <div class="msg-bubble">

              <div class="msg-info">
                <small class="msg-info-name">{{ message.name }}</small>
                <div class="msg-info-time text-center text-secondary">
                  <b>{{ moment(message.time).format('hh:mm.ss') }}</b><br>
                  <small>{{ moment(message.time).format('YYYY.MM.DD') }}</small>
                </div>
              </div>

              <div class="msg-text" v-if="!message.error_decrepting"
                   :class="{alignRight : message.rtl}">
                <small v-if="message.replay_to && getReplayedToMessage(message.replay_to)" style="opacity: 0.5">
                  {{getReplayedToMessage(message.replay_to).message}}
                  <br>
                </small>
                {{ message.message }}<br><br>
              </div>

              <div v-else class="text-danger d-flex justify-content-between align-items-center mb-2">
                <div><small>خطأ في فك تشفير الرسالة</small><br>
                  <small>الرجاء التأكد من تطابق كلمات المرور</small></div>
              </div>

              <div class="w-100 d-flex justify-content-start">
                <MessageToolBar :message="message"
                                @replayToMessage="toggleReplayToMessage($event)"></MessageToolBar>
              </div>

            </div>
          </div>

          <div class="msg right-msg" v-else-if="message.type === 'out'">
            <div class="msg-img shadow" :style="{backgroundColor : generateColorFromString(message.name)}"></div>

            <div class="msg-bubble" :class="{outgoing_has_error: message.msg_err}">
              <div class="msg-info">
                <small class="msg-info-name">{{ message.name }}</small>
                <div class="msg-info-time text-center">
                  <b>{{ moment(message.time).format('hh:mm.ss') }}</b><br>
                  <small>{{ moment(message.time).format('YYYY.MM.DD') }}</small>
                </div>
              </div>

              <div class="msg-text w-100 position-relative pb-2"
                   dir="{{message.rtl ? 'rtl' : 'ltr'}}"
                   :class="{alignRight : message.rtl}">
                <small v-if="message.replay_to && getReplayedToMessage(message.replay_to)" style="opacity: 0.5">
                  {{getReplayedToMessage(message.replay_to).message}}
                  <br>
                </small>
                {{ message.message }}
              </div>

              <small v-if="message.msg_err" class="text-warning alignRight error-decrypting-hint">
                <br> لم يتم إرسال هذه الرسالة, هناك مشكلة
                في فك التشفير </small>

              <div class="w-100 d-flex justify-content-end">
                <MessageToolBar :message="message" @removeMessage="removeMessage($event)"></MessageToolBar>
              </div>
            </div>
          </div>
        </template>
      </main>


      <div class="w-100 px-2 py-1 d-flex justify-content-between align-items-center"
           style="opacity: 0.8"
           :style="{direction: replay_to_message.rtl ? 'rtl' : 'ltr'}"
           v-if="replay_to_message">
        <small class="text-break">{{ replay_to_message.message }}</small>
        <button class="btn btn-sm btn-danger" @click="() => replay_to_message = null">X</button>
      </div>

      <div class="msger-inputarea" v-if="check_unlock_key_validity()">
        <input @keyup.enter="emit_message" v-model="message"
               ref="message_input_field"
               :class="{alignRight : containsArabic(message), alignLeft : !containsArabic(message)}"
               type="text" class="msger-input form-control shadow"
               placeholder="رسالتك هنا  your message here"/>

        <button @click="emit_message" class="shadow msger-send-btn btn-success p-0 bg-light rounded-3">
          <img src="../../assets/icns/send.svg" alt="send-icon" class="mid-icon">
        </button>
      </div>

    </section>
  </div>
</template>
<style src="./ChatBox.css"></style>
<script src="./ChatBox.js"></script>
