<template>
  <div id="set_unlock_key" class="modal fade modal-fullscreen-sm-down"
       aria-labelledby="set_unlock_key_model"
       aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">تعيين كلمة المرور</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="d-flex flex-wrap">
            <div class="input-group mb-3" v-for="(value, index) in inputs" :key="index">
              <button class="btn btn-outline-success"
                      @click="copyToClipBoard(inputs[index])"
                      type="button">نسخ
              </button>
              <input type="text" class="form-control"
                     :placeholder="'P_' + (index + 1) "
                     v-model="inputs[index]">
              <button class="btn btn-outline-primary"
                      @click="() => inputs[index] = generateRandom()"
                      type="button">توليد
              </button>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
              type="button"
              @click="emitChanges()"
              class="btn btn-primary" data-bs-dismiss="modal">تم
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./SetUnlockKeyDialog.js"></script>
<style src="./SetUnlockKeyDialog.css"></style>