export function urlHasParam(param) {
    return new URLSearchParams(window.location.search).has(param)
}

export function getUrlQueryParams() {
    const keys = Array.from(new URLSearchParams(window.location.search).keys());
    const result = {};

    keys.map((key) => {
        result[key] = new URLSearchParams(window.location.search).get(key);
    });

    return result;
}

export function copyToClipboard(text, cb) {
    if (navigator.clipboard && navigator.clipboard.writeText) {
        // Modern browsers support navigator.clipboard.writeText
        navigator.clipboard.writeText(text).then(() => {
            console.log('Text copied to clipboard:', text);
            if (cb) cb(text);
        }).catch(err => {
            console.error('Error copying text to clipboard:', err);
        });
    } else {
        // Fallback for older browsers (e.g., Internet Explorer)
        const textarea = document.createElement('textarea');
        textarea.value = text;
        textarea.style.position = 'fixed'; // Prevent scrolling to the bottom of the page
        document.body.appendChild(textarea);
        textarea.focus();
        textarea.select();

        try {
            const successful = document.execCommand('copy');
            const msg = successful ? 'successful' : 'unsuccessful';
            console.log('Text copy ' + msg + ':', text);
            if (cb) cb(text);
        } catch (err) {
            console.error('Error copying text to clipboard:', err);
        }

        document.body.removeChild(textarea);
    }
}

export function containsArabic(text) {
    const arabicRegex = /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF]/;
    return arabicRegex.test(text);
}

export function generateColorFromString(inputString) {
    let hash = 0;
    for (let i = 0; i < inputString.length; i++) {
        hash = inputString.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xFF;
        color += ('00' + value.toString(16)).substr(-2);
    }

    return color;
}

export function splitStringIntoParts(inputString, numberOfParts) {
    const partLength = Math.ceil(inputString.length / numberOfParts);
    const parts = [];

    for (let i = 0; i < inputString.length; i += partLength) {
        parts.push(inputString.substr(i, partLength));
    }

    return parts;
}

export function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

export async function pasteFromClipboard() {
    try {
        return await navigator.clipboard.readText();
    } catch (error) {
        console.error('Failed to read clipboard contents: ', error);
    }
}